import { useMutation, useQuery } from '@apollo/client'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { MESSAGES, STATUS } from '../../constants/messages'
import {
  CREATE_CLIENT_CREDENTIAL_ADMIN,
  GET_CLIENT_CREDENTIAL_ADMIN,
  GET_LAST_READ_RECORD_ADMIN,
  GET_WEB_HOOK_BY_ADMIN,
  PUSH_READ_RECORDS_ADMIN,
  PUSH_WEB_HOOK_BY_ADMIN,
  SAVE_URL_WEB_HOOK_BY_ADMIN,
  UPDATE_WEBHOOK_PASSWORD_BY_ADMIN,
} from '../../queries/adminPortal'
import {
  GET_LAST_READ_RECORD,
  GET_WEB_HOOK,
  PUSH_WEB_HOOK,
  SAVE_URL_WEB_HOOK,
  PUSH_READ_RECORDS,
  UPDATE_WEBHOOK_PASSWORD,
  GET_CLIENT_CREDENTIAL_DEV,
  CREATE_CLIENT_CREDENTIAL_DEV,
} from '../../queries/developerPortal'
import { RootState } from '../../store'
import Loading from '../../components/loading'
import ButtonBase from '../../components/partials/button'
import InputBase, { InputType } from '../../components/partials/inputBase'
import { notify } from '../../components/toastify'
import HeaderConfig from './components/headerConfig'
import { CODE_TIMEOUT, statusErrors } from '../../constants/statusCode'
import IconPassOff from '../../assets/images/ic-eye-off.svg'
import IconPassOn from '../../assets/images/ic-eye-on.svg'
import { FormInput } from '../../components/partials/formInput'
import { useForm } from 'react-hook-form'
import { LocationState } from '../../constants/interface'
import { useSession } from '../../helpers/checkSession'
import iconPlus from '../../assets/images/icon-plus.svg'
import iconDelete from '../../assets/images/icon-delete.svg'
import CreateClientCredential, {
  CreateClientCredentialFormFields,
} from './dialog/createClientCredential'
import IconDot from '../../assets/images/icon-dot.svg'
import DeleteClientCredential from './dialog/deleteClientCredential'
import { GetClientCredential } from './clientCredential'
import ActivitiesManagementAdminTable from '../developer-portal/table/activities-log/admin/activitiesMngAdminTable'
import ActivitiesManagementDevTable from '../developer-portal/table/activities-log/developer/activitiesMngDevTable'
import SwitchBasicAuthOrAuth0 from './dialog/switchBasicAuthOrAuth0'
import { KIND } from '../../constants/common'

export type RegistrationFormFields = {
  username: string
  password: string
}

const ActivitiesManagement = () => {
  const { timeOutSession, handleErrorSession } = useSession()
  const userInfoState = useSelector((state: RootState) => state.userReducers?.userInfo)
  const { state } = useLocation()
  const locationState: LocationState = state as object

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<RegistrationFormFields>()

  const [passwordType, setPasswordType] = useState<InputType>('password')
  const [isPushedFail, setIsPushedFail] = useState(false)
  const [isSwitchAuth0, setIsSwitchAuth0] = useState<boolean>(false)
  const [modalIsOpen, setIsModelOpen] = useState<boolean>(false)
  const [modalOpenDelete, setModalOpenDelete] = useState<boolean>(false)
  const [modalOpenSwitchBasicAuthOrAuth0, setModalOpenSwitchBasicAuthOrAuth0] =
    useState<boolean>(false)
  const [dataCredAuth0, setDataCredAuth0] = useState<GetClientCredential>()
  const [webhookPasswordVal, setWebhookPasswordVal] = useState<string>('')
  const btnRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const [isPopup, setIsPopup] = useState<boolean>(false)
  const activeTabClass = 'bg-primary-shade4 text-primary-shade1'
  const inActiveTabClass = 'text-neutral-4 bg-transparent'

  const handleOutsideClick = useCallback((e: any) => {
    if (
      menuRef &&
      !menuRef.current?.contains(e.target) &&
      btnRef &&
      !btnRef.current?.contains(e.target)
    ) {
      setIsPopup(false)
    }
  }, [])

  const handleClick = () => {
    setIsPopup(!isPopup)
  }

  const handleOpenDelete = () => {
    setIsPopup(false)
    setModalOpenDelete(true)
  }

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick)

    return () => {
      // Remove click outside
      document.removeEventListener('click', handleOutsideClick)
    }
  }, [isPopup])

  const isAdmin = userInfoState?.role?.name === 'Admin'

  const variablesGetLRC: any = isAdmin
    ? {
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            partyId: locationState?.partyId,
            kind: locationState?.kind,
          },
        },
      }
    : {
        fetchPolicy: 'no-cache',
      }

  // get last read record and activities log
  const {
    loading: lastReadRecordLoading,
    data: lastReadRecordData,
    error: lastReadRecordError,
    refetch,
  } = useQuery(isAdmin ? GET_LAST_READ_RECORD_ADMIN : GET_LAST_READ_RECORD, variablesGetLRC)

  // lastReadRecordData
  const lastReadRecordDataDev = lastReadRecordData?.lastRecordForCarrier
  const lastReadRecordDataAdmin = lastReadRecordData?.lastRecordByAdmin

  // get webhook info
  const getWebhookVariables: any = isAdmin
    ? {
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            partyId: locationState?.partyId,
            kind: locationState?.kind,
          },
        },
      }
    : {
        fetchPolicy: 'no-cache',
      }

  const {
    data: dataAuth0,
    error: errorAuth0,
    refetch: refetchAuth0,
  } = useQuery(
    isAdmin ? GET_CLIENT_CREDENTIAL_ADMIN : GET_CLIENT_CREDENTIAL_DEV,
    getWebhookVariables,
  )

  const {
    loading: webhookLoading,
    data: webhookData,
    error: webhookError,
    refetch: refetchGetWebhookInfo,
  } = useQuery(isAdmin ? GET_WEB_HOOK_BY_ADMIN : GET_WEB_HOOK, getWebhookVariables)

  // action push - update webhook url and push last read record
  const [pushWebHook, { data: pushWebHookData, loading: pushWebHookLoading }] = useMutation(
    isAdmin ? PUSH_WEB_HOOK_BY_ADMIN : PUSH_WEB_HOOK,
  )
  const [updateWebhook, { loading: updateWebhookLoading }] = useMutation(
    isAdmin ? SAVE_URL_WEB_HOOK_BY_ADMIN : SAVE_URL_WEB_HOOK,
  )
  const [pushReadRecords, { loading: pushReadRecordsLoading, data: pushReadRecordsData }] =
    useMutation(isAdmin ? PUSH_READ_RECORDS_ADMIN : PUSH_READ_RECORDS)

  // declare
  const webhookId = isAdmin
    ? webhookData?.getWebhookByAdmin?.webhookId
    : webhookData?.getWebhook?.webhookId
  const lastRecordRead = isAdmin
    ? pushReadRecordsData?.updateLastRecordByAdmin?.lastRecordRead
    : pushReadRecordsData?.updateLastRecordForCarrier?.lastRecordRead
  const lastRecordConfirmed = isAdmin
    ? lastReadRecordDataAdmin?.lastRecordConfirmed
    : lastReadRecordDataDev?.lastRecordConfirmed
  const [webHookUrl, setWebHookUrl] = useState<string>(
    isAdmin ? webhookData?.getWebhookByAdmin?.url : webhookData?.getWebhook?.url,
  )
  const [webHookUrlInit, setWebHookUrlInit] = useState<string>(
    isAdmin ? webhookData?.getWebhookByAdmin?.url : webhookData?.getWebhook?.url,
  )
  const [readRecords, setReadRecords] = useState(lastRecordConfirmed)
  const [readRecordsInit, setReadRecordsInit] = useState(lastRecordConfirmed)

  // data create credential
  const [dataClientCredential, setDataClientCredential] =
    useState<CreateClientCredentialFormFields>()

  //update password
  const [updatePassword] = useMutation(
    isAdmin ? UPDATE_WEBHOOK_PASSWORD_BY_ADMIN : UPDATE_WEBHOOK_PASSWORD,
  )

  // create client credential
  const [createClientCredential] = useMutation(
    isAdmin ? CREATE_CLIENT_CREDENTIAL_ADMIN : CREATE_CLIENT_CREDENTIAL_DEV,
  )

  const handleUpdatePassword = async (dataFields: RegistrationFormFields) => {
    const updatePasswordVariable: any = isAdmin
      ? {
          variables: {
            data: {
              username: dataFields.username,
              password: dataFields.password,
              partyId: locationState?.partyId,
              kind: locationState?.kind,
            },
            webhookId: webhookId,
          },
        }
      : {
          variables: {
            data: {
              username: dataFields.username,
              password: dataFields.password,
            },
            webhookId: webhookId,
          },
        }
    try {
      const dataRes = await updatePassword(updatePasswordVariable)
      if (
        dataRes &&
        !statusErrors.includes(
          dataRes?.data?.updateWebhookPassword?.statusCode ||
            dataRes?.data?.updateWebhookPasswordByAdmin?.statusCode,
        )
      ) {
        setWebhookPasswordVal('password')
        notify(MESSAGES.SUCCESS.S_PASSWORD_UPDATE_WEBHOOK, STATUS.SUCCESS)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_SOMETHING_WENT_WRONG_SYSTEM)
    }
  }

  const variablesPushLRC = isAdmin
    ? {
        variables: {
          data: {
            partyId: locationState?.partyId,
            kind: locationState?.kind,
            lastRecordRead:
              readRecords !== undefined
                ? readRecords
                : lastReadRecordData?.lastRecordByAdmin?.lastRecordConfirmed,
          },
        },
      }
    : {
        variables: {
          lastRecordRead:
            readRecords !== undefined
              ? readRecords
              : lastReadRecordData?.lastRecordForCarrier?.lastRecordConfirmed,
        },
      }

  // #start region: handle functions push, update URL webhook and update last record read
  const pushWebHookVariable: any = isAdmin
    ? {
        variables: {
          data: {
            partyId: locationState?.partyId,
            kind: locationState?.kind,
            webhookType: isSwitchAuth0 ? '1' : '0',
          },
          pushUnreadRecordByAdminId: webhookData?.getWebhookByAdmin?.webhookId,
        },
      }
    : {
        variables: {
          id: webhookData?.getWebhook?.webhookId,
          webhookType: isSwitchAuth0 ? '1' : '0',
        },
      }

  const handlePushWebHook = async () => {
    try {
      const dataRes = await pushWebHook(pushWebHookVariable)
      if (dataRes) {
        if (
          !statusErrors.includes(
            dataRes?.data?.pushUnreadRecord?.statusCode ||
              dataRes?.data?.pushUnreadRecordByAdmin?.statusCode,
          )
        ) {
          notify(MESSAGES.SUCCESS.S_PUSHED_WEBHOOK, STATUS.SUCCESS)
        } else {
          setIsPushedFail(statusErrors.includes(dataRes?.data?.pushUnreadRecordByAdmin?.statusCode))
        }
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_PUSHED_WEBHOOK)
    }
  }

  const handleRefetchWhenAddOrDeleteAuth0 = () => {
    refetchAuth0()
  }

  useEffect(() => {
    setReadRecords(lastRecordConfirmed)
    setReadRecordsInit(lastRecordConfirmed)
  }, [])

  useEffect(() => {
    setDataCredAuth0(
      isAdmin ? dataAuth0?.getClientCredentialForAdmin : dataAuth0?.getClientCredential,
    )
  }, [dataAuth0])

  useEffect(() => {
    setIsPushedFail(
      statusErrors.includes(pushWebHookData?.pushUnreadRecord?.statusCode) ||
        statusErrors.includes(pushWebHookData?.pushUnreadRecordByAdmin?.statusCode),
    )
  }, [pushWebHookData])

  useEffect(() => {
    if (webhookData?.getWebhookByAdmin?.password || webhookData?.getWebhook?.password) {
      setValue(
        'password',
        webhookData?.getWebhookByAdmin?.password || webhookData?.getWebhook?.password,
        { shouldValidate: true },
      )
      setWebhookPasswordVal(
        webhookData?.getWebhookByAdmin?.password || webhookData?.getWebhook?.password,
      )
    }
    if (webhookData?.getWebhookByAdmin?.username || webhookData?.getWebhook?.username) {
      setValue(
        'username',
        webhookData?.getWebhookByAdmin?.username || webhookData?.getWebhook?.username,
        { shouldValidate: true },
      )
    }
  }, [webhookData])

  // check data to switch default option Basic auth/Auth0
  useEffect(() => {
    if (
      dataAuth0?.getClientCredentialForAdmin?.Auth0Url ||
      dataAuth0?.getClientCredential?.Auth0Url
    ) {
      setIsSwitchAuth0(true)
    } else {
      setIsSwitchAuth0(false)
    }
  }, [dataAuth0])

  const onSubmit = handleSubmit((data) => {
    if (!dataCredAuth0?.Auth0Url) {
      setIsSwitchAuth0(false)
      handleUpdatePassword(data)
    } else {
      setModalOpenSwitchBasicAuthOrAuth0(true)
    }
  })

  // handle delete webhook password when create new client credential
  const handleProceedAuth0 = async () => {
    const updatePasswordVariable: any = isAdmin
      ? {
          variables: {
            data: {
              password: '',
              partyId: locationState?.partyId,
              kind: locationState?.kind,
            },
            webhookId: webhookId,
          },
        }
      : {
          variables: {
            data: {
              password: '',
            },
            webhookId: webhookId,
          },
        }
    try {
      const dataRes = await updatePassword(updatePasswordVariable)
      if (
        dataRes &&
        !statusErrors.includes(
          dataRes?.data?.updateWebhookPassword?.statusCode ||
            dataRes?.data?.updateWebhookPasswordByAdmin?.statusCode,
        )
      ) {
        setModalOpenSwitchBasicAuthOrAuth0(false)
        handleRefetchWhenAddOrDeleteAuth0()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_PASSWORD_UPDATE_WEBHOOK)
    }
  }

  // handle submit create client credential
  const handleCreateClientCredential = async () => {
    const variablesDev = {
      data: {
        clientId: dataClientCredential?.clientId.trim(),
        secretKey: dataClientCredential?.secretKey.trim(),
        audience: dataClientCredential?.audience.trim(),
        Auth0Url: dataClientCredential?.Auth0Url.trim(),
      },
    }
    const variablesAdmin = {
      data: {
        clientId: dataClientCredential?.clientId.trim(),
        secretKey: dataClientCredential?.secretKey.trim(),
        audience: dataClientCredential?.audience.trim(),
        Auth0Url: dataClientCredential?.Auth0Url.trim(),
        kind: locationState?.kind,
        partyId: locationState?.partyId,
      },
    }
    try {
      const dataRes = await createClientCredential({
        variables: isAdmin ? variablesAdmin : variablesDev,
      })
      if (dataRes) {
        notify(
          webhookPasswordVal
            ? MESSAGES.SUCCESS.S_WEBHOOK_SECURITY
            : MESSAGES.SUCCESS.S_CREATED_CLIENT_CREDENTIAL,
          STATUS.SUCCESS,
        )
        handleRefetchWhenAddOrDeleteAuth0()
        handleProceedAuth0()
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_CREATED_CLIENT_CREDENTIAL)
    }
  }

  // handle switch option Basic Auth or Auth0
  const switchOptionBasicAuthOrAuth0 = (val: boolean) => {
    setIsPushedFail(false)
    setIsSwitchAuth0(val)
  }

  const saveWebHookVariable = isAdmin
    ? {
        variables: {
          data: {
            url: webHookUrl !== undefined ? webHookUrl : webhookData?.getWebhookByAdmin?.url,
            partyId: locationState?.partyId,
            kind: locationState?.kind,
            webhookType: isSwitchAuth0 ? '1' : '0',
          },
          webhookId: webhookId,
        },
      }
    : {
        variables: {
          data: {
            url: webHookUrl !== undefined ? webHookUrl : webhookData?.getWebhook?.url,
            webhookType: isSwitchAuth0 ? '1' : '0',
          },
          webhookId: webhookId,
        },
      }
  const handleSaveWebHook = async () => {
    try {
      const dataRes = await updateWebhook(saveWebHookVariable)
      if (dataRes) {
        setWebHookUrl(dataRes?.data?.updateWebhook?.url || dataRes?.data?.updateWebhookByAdmin?.url)
        setWebHookUrlInit(
          dataRes?.data?.updateWebhook?.url || dataRes?.data?.updateWebhookByAdmin?.url,
        )
        notify(MESSAGES.SUCCESS.S_UPDATED_URL, STATUS.SUCCESS)
        setIsPushedFail(false)
      }
    } catch (error) {
      handleErrorSession(error, MESSAGES.ERROR.E_URL_INVALID)
      setWebHookUrl(webHookUrlInit)
      setIsPushedFail(false)
    }
  }

  const handlePushReadRecord = async (data: any) => {
    // handle show toast when last record and last record confirmed equal: 0
    if (data?.lastRecord === 0 && data?.lastRecordConfirmed === 0) {
      notify(MESSAGES.WARNING.W_RECORD_CONFIRM_MORE_THAN_LAST_RECORD, STATUS.WARNING)
      setReadRecords(0)
    } else if (readRecords > data?.lastRecord) {
      notify(MESSAGES.WARNING.W_RECORD_CONFIRM_MORE_THAN_LAST_RECORD, STATUS.WARNING)
      setReadRecords(lastRecordRead)
    } else {
      try {
        const dataRes = await pushReadRecords(variablesPushLRC)
        if (dataRes) {
          notify(MESSAGES.SUCCESS.S_UPDATED_LAST_READ_RECORD, STATUS.SUCCESS)
          setReadRecordsInit(lastRecordRead)
        }
        refetch({})
      } catch (error) {
        handleErrorSession(error, MESSAGES.ERROR.E_CANNOT_UPDATE_LAST_READ_RECORD)
        setReadRecords(readRecordsInit)
      }
    }
  }

  const handleChangeWebHookUrl = (val: string) => {
    setWebHookUrl(val)
  }

  const handleChangeReadRecords = (val: string) => {
    setReadRecords(parseFloat(val))
  }
  // #end region: handle functions push, update URL webhook and update last record read

  useEffect(() => {
    if (
      webhookError?.graphQLErrors[0]?.extensions?.code ||
      errorAuth0?.graphQLErrors[0]?.extensions?.code ||
      lastReadRecordError?.graphQLErrors[0]?.extensions?.code === CODE_TIMEOUT
    ) {
      timeOutSession()
    }
  }, [webhookError, lastReadRecordError])

  // handle loading after action
  if (
    lastReadRecordLoading ||
    webhookLoading ||
    updateWebhookLoading ||
    pushReadRecordsLoading ||
    pushWebHookLoading
  )
    return <Loading />

  const togglePassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text')
      return
    }
    setPasswordType('password')
  }

  return (
    <div className="px-20 py-9 md:px-10">
      {(locationState?.kind === KIND.CARRIERS || userInfoState?.kind === KIND.CARRIERS) && (
        <>
          {/* Webhook configuration */}
          <div>
            <div className="flex">
              <p className="text-headline5A text-neutral-1 pb-8 pr-5 mt-[6px]">
                Webhook configuration
              </p>
              <button
                className={`pb-8 h-0 px-6 mr-2 last:mr-0 py-2 text-body1 font-semibold cursor-pointer rounded-full text-center whitespace-nowrap ${
                  isSwitchAuth0 ? inActiveTabClass : activeTabClass
                }`}
                onClick={() => switchOptionBasicAuthOrAuth0(false)}
              >
                Basic Auth
              </button>
              <button
                className={`pb-8 h-0 px-6 mr-2 last:mr-0 py-2 text-body1 font-semibold cursor-pointer rounded-full text-center whitespace-nowrap ${
                  isSwitchAuth0 ? activeTabClass : inActiveTabClass
                }`}
                onClick={() => switchOptionBasicAuthOrAuth0(true)}
              >
                Auth0
              </button>
              <SwitchBasicAuthOrAuth0
                isSwitchAuth0={isSwitchAuth0}
                isAdmin={isAdmin}
                webhookId={webhookId}
                webhookPasswordVal={webhookPasswordVal}
                locationState={locationState}
                modalOpenSwitchBasicAuthOrAuth0={modalOpenSwitchBasicAuthOrAuth0}
                handleCloseDialog={() => setModalOpenSwitchBasicAuthOrAuth0(false)}
                handleRefetchWhenAddOrDeleteAuth0={handleRefetchWhenAddOrDeleteAuth0}
                refetchGetWebhookInfo={refetchGetWebhookInfo}
                handleCreateClientCredential={handleCreateClientCredential}
                handleProceedAuth0={handleProceedAuth0}
                passwordValue={getValues('password')}
              />
            </div>
            {!isSwitchAuth0 ? (
              <div className="mb-5">
                <div className="flex items-center">
                  <p className="w-[246px] font-semibold text-body1 text-neutral-3">Username</p>
                  <div className="flex w-[1024px] lg:w-[605px] md:max-w-[430px]">
                    <div className="relative w-full mr-5">
                      <FormInput<RegistrationFormFields>
                        id="username"
                        name="username"
                        label="Password"
                        placeholder="Enter your username"
                        inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
                        register={register}
                        errors={errors}
                      />
                    </div>
                    <div className="min-w-[148px]" />
                  </div>
                </div>
                <div className="flex items-center mt-5">
                  <p className="w-[246px] font-semibold text-body1 text-neutral-3">Password</p>
                  <div className="flex w-[1024px] lg:w-[605px] md:max-w-[430px]">
                    <div className="relative w-full mr-5">
                      <FormInput<RegistrationFormFields>
                        id="password"
                        type={passwordType}
                        name="password"
                        label="Password"
                        placeholder="Enter your password"
                        inputClassName="w-full flex-1 z-0 pr-12 text-neutral-3"
                        register={register}
                        errors={errors}
                      />
                      <button className="absolute right-4 top-[14px]" onClick={togglePassword}>
                        <img
                          src={passwordType === 'password' ? IconPassOff : IconPassOn}
                          alt="Icon eye"
                        />
                      </button>
                    </div>
                    <ButtonBase
                      type="submit"
                      className="bg-neutral-8 min-w-[148px] p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
                      onClick={onSubmit}
                    >
                      Set
                    </ButtonBase>
                  </div>
                </div>
              </div>
            ) : (
              <div className="mb-5">
                {!dataCredAuth0?.clientId ? (
                  <>
                    <div className="flex items-center">
                      <p className="w-[246px] font-semibold text-body1 text-neutral-3">
                        Client credential
                      </p>
                      <ButtonBase
                        type="reset"
                        className="flex justify-start bg-neutral-8 w-[282px] p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
                        onClick={() => setIsModelOpen(true)}
                      >
                        <img src={iconPlus} alt="iconPlus" className="pl-6 pr-3" />
                        Create Client Credential
                      </ButtonBase>
                    </div>
                    <CreateClientCredential
                      modalIsOpen={modalIsOpen}
                      handleCloseDialog={() => {
                        setIsModelOpen(false)
                      }}
                      isAdmin={isAdmin}
                      locationState={locationState}
                      webhookPasswordVal={webhookPasswordVal}
                      handleRefetchWhenAddOrDeleteAuth0={handleRefetchWhenAddOrDeleteAuth0}
                      setModalOpenSwitchBasicAuthOrAuth0={setModalOpenSwitchBasicAuthOrAuth0}
                      setDataClientCredential={setDataClientCredential}
                    ></CreateClientCredential>
                  </>
                ) : (
                  <div className="flex">
                    <p className="min-w-[246px] md:min-w-[208px] font-semibold text-body1 text-neutral-3 pt-4">
                      Client credential
                    </p>
                    <div className="pr-0 md:px-10 md:pl-9">
                      <div className="flex flex-wrap justify-between gap-6">
                        <div className="w-[1024px] md:w-[432px] lg:max-w-[606px] p-5 rounded-[16px] bg-neutral-7-header-footer border-2 border-neutral-7">
                          <div className="flex">
                            <div className="w-[22.5%] lg:w-[28%] md:w-[35%]">
                              <p className="text-body2 text-neutral-3">Client ID</p>
                            </div>
                            <div className="w-[80%] md:w-[65%] pl-10 lg:pl-6">
                              <p className="font-semibold break-all text-body2 text-neutral-3 md:ml-[-4px]">
                                {dataCredAuth0?.clientId}
                              </p>
                            </div>
                            <div className="relative">
                              <div
                                className="cursor-pointer pl-7"
                                onClick={handleClick}
                                ref={btnRef}
                              >
                                <img
                                  src={IconDot}
                                  alt="Icon-Dot"
                                  className="min-h-[20px] min-w-[20px]"
                                />
                              </div>
                              {isPopup && (
                                <div
                                  ref={menuRef}
                                  className="absolute right-0 mt-2 md:mr-[-40px] rounded-xl bg-neutral-8 p-3 w-60 shadow-[0_12px_24px_rgba(0,0,0,0.2)] border border-neutral-8"
                                >
                                  <ButtonBase
                                    type="reset"
                                    className="bg-neutral-8 w-[200px] p-0 text-indicator-red hover:bg-neutral-8 flex justify-start h-7"
                                    onClick={handleOpenDelete}
                                  >
                                    <img src={iconDelete} alt="iconPlus" className="pr-2" />
                                    Delete
                                  </ButtonBase>
                                </div>
                              )}
                              <DeleteClientCredential
                                modalOpenDelete={modalOpenDelete}
                                isAdmin={isAdmin}
                                locationState={locationState}
                                handleCloseDeleteDialog={() => {
                                  setModalOpenDelete(false)
                                }}
                                handleRefetchWhenAddOrDeleteAuth0={
                                  handleRefetchWhenAddOrDeleteAuth0
                                }
                              />
                            </div>
                          </div>
                          <div className="flex pt-3">
                            <div className="w-[20%] lg:w-[25%] md:w-[30%]">
                              <p className="text-body2 text-neutral-3">Client Secret</p>
                            </div>
                            <div className="w-[80%] md:w-[70%] pl-12 pb-2 lg:pl-6">
                              <p className="flex font-semibold text-body2 text-neutral-3 wordSpace">
                                {dataCredAuth0?.secretKey.replace(/./g, '*')}
                              </p>
                            </div>
                          </div>
                          <div className="flex pt-3">
                            <div className="w-[20%] lg:w-[25%] md:w-[30%]">
                              <p className="text-body2 text-neutral-3">URL Auth Token</p>
                            </div>
                            <div className="w-[80%] md:w-[70%] pl-12 lg:pl-6">
                              <p className="font-semibold break-all text-body2 text-neutral-3">
                                {dataCredAuth0?.Auth0Url}
                              </p>
                            </div>
                          </div>
                          <div className="flex pt-3">
                            <div className="w-[20%] lg:w-[25%] md:w-[30%]">
                              <p className="text-body2 text-neutral-3">Audience</p>
                            </div>
                            <div className="w-[80%] md:w-[70%] pl-12 lg:pl-6">
                              <p className="font-semibold break-all text-body2 text-neutral-3">
                                {dataCredAuth0?.audience}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!isSwitchAuth0 && (
              <div className="flex my-6">
                <div className="w-[246px]" />
                <div className="w-[1024px] lg:w-[605px] md:max-w-[430px] border-b-[1px] border-neutral-6" />
              </div>
            )}
            <div className="mb-5">
              <div className="flex items-center">
                <p className="min-w-[246px] font-semibold text-body1 text-neutral-3">Webhook URL</p>
                <div className="flex">
                  <InputBase
                    placeholder="Enter your webhook URL"
                    className={`text-neutral-3 w-[854px] lg:w-[438px] md:max-w-[262px] z-0 mr-5 ${
                      isPushedFail && 'border-2 border-indicator-yellow2'
                    }`}
                    value={
                      webHookUrl !== undefined
                        ? webHookUrl
                        : (webhookData?.getWebhook?.url || webhookData?.getWebhookByAdmin?.url) ??
                          ''
                    }
                    handleChange={handleChangeWebHookUrl}
                  />
                  <ButtonBase
                    type="reset"
                    className="bg-neutral-8 w-[148px] p-0 border-2 border-primary-1 text-primary-1 hover:bg-primary-shade4"
                    onClick={handleSaveWebHook}
                  >
                    Update URL
                  </ButtonBase>
                </div>
              </div>
              {isPushedFail && (
                <div className="flex pt-1">
                  <div className="w-[246px]"></div>
                  <div>
                    <p className="text-indicator-yellow2 text-body2">
                      The webhook URL failed to receive the message pushed.
                    </p>
                  </div>
                </div>
              )}
            </div>
            <HeaderConfig
              refetchGetWebhookInfo={refetchGetWebhookInfo}
              headerConfigData={
                isAdmin
                  ? webhookData?.getWebhookByAdmin?.webhookHeader
                  : webhookData?.getWebhook?.webhookHeader
              }
              partyId={locationState?.partyId}
            />
            <div className="flex justify-end">
              <ButtonBase
                type="submit"
                className="w-[148px] bg-primary-1 min-w-min text-center"
                onClick={handlePushWebHook}
              >
                Push
              </ButtonBase>
            </div>
          </div>
          <hr className="my-10 border-t-neutral-6" />
          {/* Read records number */}
          <div>
            <p className="pb-5 text-headline5A text-neutral-1">Read records number</p>
            <div className="grid grid-cols-4 sm:grid-cols-4 lg:grid-cols-1 gap-x-6">
              <div className="flex items-center col-span-2 mr-10">
                <p className="text-body1 font-semibold text-neutral-3 w-[246px]">
                  Last read record confirmed
                </p>
                <div>
                  <InputBase
                    placeholder="Records"
                    type="number"
                    className="w-[124px] text-center text-body1 text-neutral-3 mr-5"
                    value={readRecords !== undefined ? readRecords : lastRecordConfirmed ?? 0}
                    handleChange={handleChangeReadRecords}
                  />
                  <ButtonBase
                    type="submit"
                    className="w-40 bg-primary-1 min-w-min"
                    onClick={() =>
                      handlePushReadRecord(
                        isAdmin ? lastReadRecordDataAdmin : lastReadRecordDataDev,
                      )
                    }
                  >
                    Update
                  </ButtonBase>
                </div>
              </div>
              <div className="col-span-2 pt-[10px]">
                <div className="grid grid-cols-3 sm:grid-cols-3 lg:grid-cols-1 gap-x-6">
                  <div className="flex items-baseline justify-start mr-1 lg:mt-3">
                    <p className="text-body1 text-neutral-3 lg:w-[226px] font-semibold mr-2">
                      Latest record
                    </p>
                    <span className="text-headline5A mr-2 ml-2 text-secondary-bold1 lg:ml-[25px]">
                      {lastReadRecordDataDev?.lastRecord ||
                        lastReadRecordDataAdmin?.lastRecord ||
                        0}
                    </span>
                  </div>
                  <div className="flex items-baseline justify-start lg:mt-4">
                    <p className="text-body1 font-semibold text-neutral-3 lg:w-[226px] mr-2">
                      Unread records
                    </p>
                    <span className="text-headline5A ml-2 text-indicator-red lg:ml-[25px]">
                      {lastReadRecordData?.lastRecordForCarrier?.unreadRecord ||
                        lastReadRecordData?.lastRecordByAdmin?.unreadRecord}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-10 border-t-neutral-6" />
        </>
      )}
      {/* Activities log */}
      <div>
        <p className="pb-5 text-headline5A text-neutral-1">Activities log</p>
        {isAdmin ? <ActivitiesManagementAdminTable /> : <ActivitiesManagementDevTable />}
      </div>
    </div>
  )
}

export default ActivitiesManagement
